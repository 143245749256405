export const contextPath = '/GlobalDonations/gdms';

export const uploadFileSize = 10485760;

export const landigPageUrl = `${contextPath}/Public/landingPage `;

export enum DONATION_TYPE {
    MONETARY = 'donation-money',
    GOODSANDFOOD = 'donation-goods',
    FOOD_ONE_TIME = 'FOOD_ONE_TIME',

}

export enum RecipientTypes {
    INDIVIDUAL = 'INDIVIDUAL',
    NGO = 'NGO',
    GE = 'GE'
}


export enum DONATION_TYPE_VALUES {
  MONETARY = 'DONATION_OF_MONEY',
  GOODSANDFOOD = 'DONATION_OF_GOODS_RECURRING',
  FOOD_ONE_TIME = 'FOOD_ONE_TIME',
  DONATION_OF_GOODS_ONE_TIME = 'DONATION_OF_GOODS_ONE_TIME',
  SPACE_INTERNATIONAL = 'SPACE_INTERNATIONAL',
  EMERGENCY_DONATION = 'EMERGENCY_DONATION',
  SERVICE_DONATION = 'SERVICE_DONATION'
}

export enum DONATION_TYPE_MAP {
    DONATION_OF_MONEY = 'donationOfMoney',
    DONATION_OF_GOODS_RECURRING = 'donationOfGoods',
    FOOD_ONE_TIME = 'FOOD_ONE_TIME',
    SPACE_INTERNATIONAL = 'spaceInternational'
}

export enum DONATION_STATUS {
    REQUEST_FOR_INFORMATION = 'REQUEST_FOR_INFORMATION',
    UNDER_CA_REVIEW = 'UNDER_CA_REVIEW',
    UNDER_AC_REVIEW = 'UNDER_AC_REVIEW',
    DRAFT = 'DRAFT',
    UNDER_RDA_REVIEW = 'UNDER_CA_REVIEW',
    AWAITING_PAYMENT_ACKNOWLEDGMENT = 'AWAITING_PAYMENT_ACKNOWLEDGMENT'
}


export const routePaths = {
    'mx_fill_more_details': `/donation/${DONATION_TYPE.MONETARY}/more-details`,
    'mx_fill_more_details_goods': `/donation/${DONATION_TYPE.GOODSANDFOOD}/more-details`,
    'recurring_goods_appendix_c': `/donation/${DONATION_TYPE.GOODSANDFOOD}/fill-appendix-c`
}


export const routePathsRelative = {
    'mx_fill_more_details': `donation/${DONATION_TYPE.MONETARY}/more-details`,
    'mx_fill_more_details_goods': `donation/${DONATION_TYPE.GOODSANDFOOD}/more-details`,
    'recurring_goods_appendix_c': `donation/${DONATION_TYPE.GOODSANDFOOD}/fill-appendix-c`
}

export const caApproverRole = 'Project Manager';
export const acApproverRole = 'Anti-Corruption Approver';
export const acAndAcApproverRole = 'caAndAcuserRole';
export const adminRole = 'Admin';
export const storeUserRole = 'STORE_USER';
export const commonFormTranslations: any = {
    failedToDownload: {
        en: 'Failed to download file',
        es: 'No se pudo descargar el archivo',
        fr: 'Échec du téléchargement du fichier',
        ja: 'ファイルのダウンロードに失敗しました',
        zh: '下载文件失败',
    },
    failedToUpload: {
        en: 'Failed to upload the file.',
        es: 'No se pudo cargar el archivo.',
        fr: 'Échec du téléchargement du fichier.',
        ja: 'ファイルのアップロードに失敗しました。',
        zh: '无法上传文件。',
    },
    genericErrorMessage: {
        en: 'Something went wrong. Please try again later',
        es: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde',
        fr: 'Un problème est survenu. Veuillez réessayer plus tard',
        ja: '何かがうまくいかなかった。後でもう一度やり直してください',
        zh: '出問題了。請稍後再試'
    },
    btn: {
        back: {
            en: 'BACK',
            es: 'VOLVER',
            fr: 'RETOUR',
            ja: 'リストに戻る',
            zh: '返回列表'
        },
        cancel: {
            en: 'CANCEL',
            es: 'CANCELAR',
            fr: 'ANNULER',
            ja: 'キャンセル',
            zh: '取消'
        },
        submit: {
            en: 'SUBMIT',
            es: 'ENVIAR',
            fr: 'SOUMETTRE',
            ja: 'レポートを送信する',
            zh: '提交報告'
        }
    },
    validationMsg: {
        required: {
            en: 'This field is required',
            es: 'este campo es requerido',
            fr: 'Ce champ est requis',
            ja: 'この項目は必須です',
            zh: '這是必填欄'
        },
        numbersOnly: {
            en: 'This field must contain only numbers',
            es: 'Este campo debe contener solo números',
            fr: 'Ce champ ne doit contenir que des chiffres',
            ja: 'このフィールドには数字のみを含める必要があります',
            zh: '此字段只能包含數字'
        },
        positiveNumbersOnly: {
            en: 'This field must contain numbers greater than zero',
            es: 'Este campo debe contener números mayores que cero',
            fr: 'Ce champ doit contenir des nombres supérieurs à zéro',
            ja: 'このフィールドには、ゼロより大きい数値を含める必要があります',
            zh: '此字段必須包含大於零的數字'
        },
        nonZeroNumbersOnly: {
            en: 'This field must contain only numbers except zero',
            es: 'Este campo debe contener solo números excepto cero',
            fr: 'Ce champ ne doit contenir que des nombres sauf zéro',
            ja: 'このフィールドには、ゼロ以外の数値のみを含める必要があります',
            zh: '該字段只能包含數字（零除外)'
        },
        duplicateStoreNo: {
            en: 'Duplicate facility# :',
            es: 'Número de instalación duplicado:',
            fr: 'Dupliquer le numéro de l\'établissement:',
            ja: '重複施設＃：',
            zh: '設施重複＃：'
        },
        validAmountOnly: {
            en: 'This field must contain a valid amount',
            es: 'Este campo debe contener una cantidad válida',
            fr: 'Ce champ doit contenir un montant valide',
            ja: 'このフィールドには有効な金額が含まれている必要があります',
            zh: '此字段必須包含有效金額'
        },
        invalidStoreNo: {
            en: 'Invalid Facility #',
            es: 'Número de tienda no válido',
            fr: 'Numéro de magasin non valide',
            ja: '店舗番号が無効です',
            zh: '無效的商店編號'
        }
    }
}
export const countryCodeToLanguageCode: { [key: string]: string } = {
    AR: 'ES',
    BR: 'PT',
    BW: 'EN',
    CA: 'FR',
    CL: 'ES',
    CN: 'ZH',
    CR: 'ES',
    GB: 'EN',
    GH: 'ES',
    GT: 'ES',
    HN: 'ES',
    IN: 'EN',
    JP: 'JA',
    KE: 'EN',
    LS: 'EN',
    MW: 'EN',
    MX: 'ES',
    MZ: 'EN',
    NA: 'EN',
    NG: 'ES',
    NI: 'ES',
    SV: 'ES',
    SZ: 'EN',
    TZ: 'EN',
    UG: 'EN',
    ZA: 'EN',
    ZM: 'EN'
}

export const countryCodeToMarketCode: { [key: string]: string } = {
    AR: 'AR',
    BR: 'BR',
    BW: 'ZA',
    CA: 'CA',
    CL: 'CL',
    CN: 'CN',
    CR: 'K1',
    GB: 'GB',
    GH: 'ZA',
    GT: 'K1',
    HN: 'K1',
    IN: 'IN',
    JP: 'JP',
    KE: 'ZA',
    LS: 'ZA',
    MW: 'ZA',
    MX: 'MX',
    MZ: 'ZA',
    NA: 'ZA',
    NG: 'ZA',
    NI: 'K1',
    SV: 'K1',
    SZ: 'ZA',
    TZ: 'ZA',
    UG: 'ZA',
    ZA: 'ZA',
    ZM: 'ZA'
}

export const donationsStatusText: any = {

    COMMUNITY_GRANT: {
        en: {
            SUBMITTED: 'Submitted',
            PENDING_APPROVAL: 'Pending Approval',
            COMPLETED: 'Completed',
            PENDING_AUTHORIZATION: 'Pending Authorization',
            REJECTED: 'Rejected',
            DECLINED_NOTIFIED: 'Declined Notified',
            REJECTED_NOTIFIED: 'Rejected Notified',
            DECLINED: 'Declined',
            CONTRACT_REJECTED: 'Contract Rejected',
            REVOKED: 'Revoked',
            STORE_REJECTED: 'Store Rejected',
            DELETED: 'Deleted',
            PAID: 'Paid'
        },
        fr: {
            SUBMITTED: 'Soumis',
            PENDING_APPROVAL: 'En attente de validation',
            COMPLETED: 'Terminé',
            PENDING_AUTHORIZATION: 'Autorisation en attente',
            REJECTED: 'Rejeté',
            DECLINED_NOTIFIED: 'Refusé Notifié',
            REJECTED_NOTIFIED: 'Rejected Notified',
            DECLINED: 'Diminué',
            CONTRACT_REJECTED: 'Contrat rejeté',
            REVOKED: 'Révoqué',
            STORE_REJECTED: 'Magasin rejeté',
            DELETED: 'Supprimé',
            PAID: 'Payé'
        }
    },
    UNMANNED_COLLECTION: {
        en: {
            STORE_REJECTED: 'Store Rejected',
            COLLECTION_REMOVED: 'Collection Removed',
            APPROVED: 'Approved',
            COMMUNITY_CHAMPION_REVIEW: 'Community Champion Review',
        },
        fr: {
            STORE_REJECTED: 'Magasin rejeté',
            COLLECTION_REMOVED: 'Collection supprimée',
            APPROVED: 'Approuvé',
            COMMUNITY_CHAMPION_REVIEW: 'Examen du champion de la communauté',
        },
        es: {
            STORE_REJECTED: 'Tienda rechazada',
            COLLECTION_REMOVED: 'Colección eliminada',
            APPROVED: 'Aprobado',
            COMMUNITY_CHAMPION_REVIEW: 'Revisión del campeón de la comunidad',
        },
        zh: {
            STORE_REJECTED: '商店被拒绝',
            COLLECTION_REMOVED: '收藏集已删除',
            APPROVED: '已批准',
            COMMUNITY_CHAMPION_REVIEW: '社区冠军回顾',
        },
        ja: {
            STORE_REJECTED: 'ストアが拒否されました',
            COLLECTION_REMOVED: 'コレクションが削除されましたd',
            APPROVED: '承認済み',
            COMMUNITY_CHAMPION_REVIEW: 'コミュニティチャンピオンレビュー',
        },
    },
    DONATION_OF_MONEY: {
        en: {
            SUBMITTED: 'Submitted',
            UNDER_CA_REVIEW: 'Under CA Review',
            UNDER_AC_REVIEW: 'Under AC Review',
            DRAFT: 'Draft',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: 'Awaiting Payment Acknowledgment',
            AWAITING_PAYMENT_CONFIRMATION: 'Awaiting Payment confirmation',
            REQUEST_FOR_INFORMATION: 'Request For Information',
            PENDING_CONTRACT_CREATION: 'Pending Contract Creation',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contract Created Outside System',
            CONTRACT_CREATED: 'Contract Created',
            COMPLETED: 'Completed',
            REJECTED: 'Rejected',
            COUNTRY_HEAD_REVIEW: 'Under Country Head Review',
            LEGAL_TEAM_REVIEW: 'Under Legal Team Review',
            UNDER_INITIAL_REVIEW: 'Under Initial AC Review',
            CANCELLED: 'Cancelled',
            UNDER_CSR_LEAD_REVIEW: 'Under CSR Lead Review',
            UNDER_BU_REVIEW: 'Under BU Review',
            UNDER_VP_REVIEW: 'Under VP Review',
            SAVED_APPENDIX_C: 'Saved Appendix C',
            WAITING_FOR_APPENDIX_C_REVIEW: 'Waiting for Appendix C Review'
        },
        es: {
            SUBMITTED: 'Presentada',
            UNDER_CA_REVIEW: 'Bajo revisión de CA',
            UNDER_AC_REVIEW: 'En revisión de A/C',
            DRAFT: 'Borrador',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: 'Esperando confirmación de pago',
            AWAITING_PAYMENT_CONFIRMATION: 'Esperando confirmación de pago',
            REQUEST_FOR_INFORMATION: 'Solicitud de información',
            PENDING_CONTRACT_CREATION: 'Creación de contrato pendiente',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contrato creado fuera del sistema',
            CONTRACT_CREATED: 'Contrato creado',
            COMPLETED: 'Completado',
            REJECTED: 'Rechazado',
            UNDER_INITIAL_REVIEW: 'Bajo revisión inicial de AC',
            CANCELLED: 'Cancelada',
            UNDER_CSR_LEAD_REVIEW: 'Bajo revisión de CSR Lead',
            UNDER_BU_REVIEW: 'Bajo revisión de BU',
            UNDER_VP_REVIEW: 'Bajo revisión de VP',
            SAVED_APPENDIX_C: 'Apéndice C guardado',
            WAITING_FOR_APPENDIX_C_REVIEW: 'Esperando la revisión del Apéndice C'
        },
        fr: {
            SUBMITTED: 'Soumis',
            UNDER_CA_REVIEW: 'Sous examen CA',
            UNDER_AC_REVIEW: 'Sous examen AC',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: 'En attente de confirmation de paiement',
            AWAITING_PAYMENT_CONFIRMATION: 'En attente de confirmation de paiement',
            REQUEST_FOR_INFORMATION: 'Demande d\'informations',
            PENDING_CONTRACT_CREATION: 'En attente de création du contrat',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contrat créé en dehors du système',
            CONTRACT_CREATED: 'Contrat créé',
            COMPLETED: 'Terminé',
            REJECTED: 'Rejeté',
            UNDER_INITIAL_REVIEW: 'En cours d\'examen initial du CA',
            CANCELLED: 'Annulé',
            UNDER_CSR_LEAD_REVIEW: `En cours d'examen par le CSR Lead`,
            UNDER_BU_REVIEW: `En cours d'examen par le BU`,
            UNDER_VP_REVIEW: `En cours d'examen par le VP`,
            SAVED_APPENDIX_C: 'Annexe C sauvegardée',
            WAITING_FOR_APPENDIX_C_REVIEW: `En attente de l'examen de l'annexe C`
        },
        ja: {
            SUBMITTED: '提出済み',
            UNDER_CA_REVIEW: 'CAレビュー中',
            UNDER_AC_REVIEW: 'ACレビュー中',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: '支払いの確認待ち',
            AWAITING_PAYMENT_CONFIRMATION: '支払い確認待ち',
            REQUEST_FOR_INFORMATION: '情報のリクエスト',
            PENDING_CONTRACT_CREATION: '契約保留中',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'システム外で作成された契約',
            CONTRACT_CREATED: '契約が作成されました',
            COMPLETED: '完了',
            REJECTED: '拒否',
            UNDER_INITIAL_REVIEW: '初期 AC レビュー中',
            CANCELLED: 'キャンセル',
            UNDER_CSR_LEAD_REVIEW: 'CSR Lead レビュー中',
            UNDER_BU_REVIEW: 'BU レビュー中',
            UNDER_VP_REVIEW: 'VP レビュー中',
            SAVED_APPENDIX_C: '保存された付録 C',
            WAITING_FOR_APPENDIX_C_REVIEW: '付録 C のレビュー待ち'
        },
        zh: {
            SUBMITTED: '已提交',
            UNDER_CA_REVIEW: '正在进行CA审核',
            UNDER_AC_REVIEW: '正在接受AC审核',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: '正在等待付款确认',
            AWAITING_PAYMENT_CONFIRMATION: '正在等待付款确认',
            REQUEST_FOR_INFORMATION: '请求信息',
            PENDING_CONTRACT_CREATION: '正在创建合同',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: '外部系统创建的合同',
            CONTRACT_CREATED: '合同已创建',
            COMPLETED: '已完成',
            REJECTED: '已拒绝',
            UNDER_INITIAL_REVIEW: '正在接受初步 AC 審查',
            CANCELLED: '取消',
            UNDER_CSR_LEAD_REVIEW: '正在接受 CSR 牵头审查',
            UNDER_BU_REVIEW: 'BU审核下',
            UNDER_VP_REVIEW: 'VP审核下',
            SAVED_APPENDIX_C: '保存附录 C',
            WAITING_FOR_APPENDIX_C_REVIEW: '等待附录 C 审查'
        }
    },
    DONATION_OF_GOODS_RECURRING: {
        en: {
            SUBMITTED: 'Submitted',
            UNDER_CA_REVIEW: 'Under CA Review',
            UNDER_AC_REVIEW: 'Under AC Review',
            REQUEST_FOR_INFORMATION: 'Request For Information',
            PENDING_CONTRACT_CREATION: 'Pending Contract Creation',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contract Created Outside System',
            CONTRACT_CREATED: 'Contract Created',
            CONTRACT_UPLOADED: 'Confirmed Contract Sent To AC',
            PENDING_AUTHORIZATION: 'Pending Authorization',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'Waiting for NGO acknowledgment',
            WAITING_FOR_REVIEW_FROM_CA: 'Waiting for review from CA',
            AUTHORIZED: 'Authorized',
            COMPLETED: 'Completed',
            REJECTED: 'Rejected',
            REVOKED: 'Revoked',
            DRAFT: 'Draft',
            CANCELLED: 'Canceled'
        },
        es: {
            SUBMITTED: 'Presentada',
            UNDER_CA_REVIEW: 'Bajo revisión de CA',
            UNDER_AC_REVIEW: 'En revisión de A/C',
            REQUEST_FOR_INFORMATION: 'Solicitud de información',
            PENDING_CONTRACT_CREATION: 'Creación de contrato pendiente',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contrato creado fuera del sistema',
            CONTRACT_CREATED: 'Contrato creado',
            CONTRACT_UPLOADED: 'Contrato confirmado enviado a AC',
            PENDING_AUTHORIZATION: 'Autorización pendiente',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'Esperando el reconocimiento de la ONG',
            WAITING_FOR_REVIEW_FROM_CA: 'Esperando revisión de CA',
            AUTHORIZED: 'Autorizado',
            COMPLETED: 'Completado',
            REJECTED: 'Rechazado',
            REVOKED: 'Revocado',
            DRAFT: 'Borrador',
        },
        fr: {
            SUBMITTED: 'Soumis',
            UNDER_CA_REVIEW: 'Sous examen CA',
            UNDER_AC_REVIEW: 'Sous examen AC',
            REQUEST_FOR_INFORMATION: 'Demande d\'informations',
            PENDING_CONTRACT_CREATION: 'En attente de création du contrat',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contrat créé en dehors du système',
            CONTRACT_CREATED: 'Contrat créé',
            CONTRACT_UPLOADED: 'Contrat confirmé envoyé á AC',
            PENDING_AUTHORIZATION: 'Autorisation en attente',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'En attente de la reconnaissance des ONG',
            WAITING_FOR_REVIEW_FROM_CA: 'En attente d\'avis de la CA',
            AUTHORIZED: 'Autorisé',
            COMPLETED: 'Terminé',
            REJECTED: 'Rejeté',
            REVOKED: 'Révoqué'
        },
        ja: {
            SUBMITTED: '提出済み',
            UNDER_CA_REVIEW: 'CAレビュー中',
            UNDER_AC_REVIEW: 'ACレビュー中',
            REQUEST_FOR_INFORMATION: '情報のリクエスト',
            PENDING_CONTRACT_CREATION: '契約保留中',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'システム外で作成された契約',
            CONTRACT_CREATED: '契約が作成されました',
            CONTRACT_UPLOADED: 'ACに送信された確認済みの契約',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'NGOの承認を待っています',
            WAITING_FOR_REVIEW_FROM_CA: 'CAからのレビューを待っています',
            PENDING_AUTHORIZATION: '承認待ち',
            AUTHORIZED: '承認済み',
            COMPLETED: '完了',
            REJECTED: '拒否',
            REVOKED: '取り消されました'
        },
        zh: {
            SUBMITTED: '已提交',
            UNDER_CA_REVIEW: '正在进行CA审核',
            UNDER_AC_REVIEW: '正在接受AC审核',
            REQUEST_FOR_INFORMATION: '请求信息',
            PENDING_CONTRACT_CREATION: '正在创建合同',
            CONTRACT_CREATED_OUTSIDE_SYSTEM: '外部系统创建的合同',
            CONTRACT_CREATED: '合同已创建',
            CONTRACT_UPLOADED: '已確認合同已發送給AC',
            PENDING_AUTHORIZATION: '待授权',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: '等待非政府組織確認',
            WAITING_FOR_REVIEW_FROM_CA: '等待 CA 審核',
            AUTHORIZED: '授权',
            COMPLETED: '已完成',
            REJECTED: '已拒绝',
            REVOKED: '已撤销'
        }
    },
    ALL: {
        en: {
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contract Created Outside System',
            CONTRACT_CREATED: 'Contract Created',
            CONTRACT_UPLOADED: 'Contract Uploaded',
            UNDER_CA_REVIEW: 'Under CA Review',
            UNDER_AC_REVIEW: 'Under AC Review',
            WAITING_FOR_COLLECTION: 'Waiting for Collection',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'Waiting for NGO acknowledgment',
            WAITING_FOR_CA_ACKNOWLEDGEMENT: 'Waiting for CA User Acknowledgement',
            COMPLETED: 'Completed',
            CANCELLED: 'Canceled',
            WAITING_FOR_APPENDIX_B: 'Waiting for Appendix B',
            WAITING_FOR_INFORMATION_FROM_STORE: 'Waiting for Information from Store',
            APPROVED: 'Approved',
            WAITING_FOR_INFORMATION_FROM_CA: 'Waiting for more information from CA',
            REJECTED: 'Rejected',
            PENDING_CONTRACT_CREATION: 'Pending Contract Creation',
            SUBMITTED: 'Submitted',
            REQUEST_FOR_INFORMATION: 'Request For Information',
            PENDING_AUTHORIZATION: 'Pending Authorization',
            AUTHORIZED: 'Authorized',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: 'Awaiting Payment Acknowledgment',
            AWAITING_PAYMENT_CONFIRMATION: 'Awaiting Payment confirmation',
            REVOKED: 'Revoked',
            REQUEST_FOR_INFORMATION_BY_AC: 'Waiting for more information from CA',
            REQUEST_FOR_INFORMATION_BY_CA: 'Waiting for Information from Store',
            WAITING_FOR_INFORMATION_FROM_ORGANIZATION: 'Waiting for Information from Organization',
            DRAFT: 'Draft',
            COUNTRY_HEAD_REVIEW: 'Under Country Head Review',
            LEGAL_TEAM_REVIEW: 'Under Legal Team Review',
            WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT: 'Waiting for Organization Acknowledgment',
            UNDER_RDA_REVIEW: 'Under RDA Review',
            WAITING_FOR_APPENDIX_H: 'Waiting for Appendix H',
            WAITING_FOR_COLLECTION_FROM_STORE: 'Waiting for collection from store',
            WAITING_FOR_RDA_ACKNOWLEDGEMENT: 'Waiting for RDA Acknowledgement',
            AWAITING_RDA_ACKNOWLEDGEMENT: 'Awaiting RDA Acknowledgement',
            PENDING_APPROVAL: 'Pending Approval',
            CANCELLATION_DUE_TO_INACTIVITY:'Inactive Cancellation'
        },
        es: {
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contrato creado fuera del sistema',
            CONTRACT_CREATED: 'Contrato creado',
            CONTRACT_UPLOADED: 'Contrato cargado',
            UNDER_CA_REVIEW: 'Bajo revisión de CA',
            UNDER_AC_REVIEW: 'En revisión de A/C',
            WAITING_FOR_COLLECTION: 'Esperando colección',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'Esperando el reconocimiento de la ONG',
            WAITING_FOR_CA_ACKNOWLEDGEMENT: 'Esperando el reconocimiento del usuario de CA',
            COMPLETED: 'Completado',
            CANCELLED: 'Cancelada',
            WAITING_FOR_APPENDIX_B: 'Esperando el Apéndice B',
            WAITING_FOR_INFORMATION_FROM_STORE: 'Esperando información de la tienda',
            APPROVED: 'Aprobado',
            WAITING_FOR_INFORMATION_FROM_CA: 'Esperando más información de CA',
            REJECTED: 'Rechazado',
            PENDING_CONTRACT_CREATION: 'Creación de contrato pendiente',
            SUBMITTED: 'Presentada',
            REQUEST_FOR_INFORMATION: 'Solicitud de información',
            PENDING_AUTHORIZATION: 'Autorización pendiente',
            AUTHORIZED: 'Autorizado',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: 'Esperando confirmación de pago',
            AWAITING_PAYMENT_CONFIRMATION: 'Esperando confirmación de pago',
            REVOKED: 'Revocado',
            WAITING_FOR_INFORMATION_FROM_ORGANIZATION: 'Esperando más información de la organización',
            WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT: 'Esperando el reconocimiento de la organización',
            UNDER_RDA_REVIEW: 'Bajo revisión RDA',
            WAITING_FOR_APPENDIX_H: "Esperando el Apéndice H",WAITING_FOR_COLLECTION_FROM_STORE:"Esperando la colección de la tienda",
            WAITING_FOR_RDA_ACKNOWLEDGEMENT: 'Esperando confirmación de RDA',
            AWAITING_RDA_ACKNOWLEDGEMENT: 'En espera de reconocimiento de RDA',
            PENDING_APPROVAL: 'Aprobación pendiente',
            CANCELLATION_DUE_TO_INACTIVITY:'Cancelación inactiva'
        },
        fr: {
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'Contrat créé en dehors du système',
            CONTRACT_CREATED: 'Contrat créé',
            CONTRACT_UPLOADED: 'Contrat téléchargé',
            UNDER_CA_REVIEW: 'Sous examen CA',
            UNDER_AC_REVIEW: 'Sous examen AC',
            WAITING_FOR_COLLECTION: 'En attente de collecte',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'En attente de la reconnaissance des ONG',
            WAITING_FOR_CA_ACKNOWLEDGEMENT: 'En attente de l\'accusé de réception de l\'utilisateur CA',
            COMPLETED: 'Terminé',
            CANCELLED: 'Annulé',
            WAITING_FOR_APPENDIX_B: 'En attente de l\'annexe B',
            WAITING_FOR_INFORMATION_FROM_STORE: 'En attente d\'informations du magasin',
            APPROVED: 'Approuvé',
            WAITING_FOR_INFORMATION_FROM_CA: `En attente de plus d'informations de CA`,
            REJECTED: 'Rejeté',
            PENDING_CONTRACT_CREATION: 'En attente de création du contrat',
            SUBMITTED: 'Soumis',
            REQUEST_FOR_INFORMATION: 'Demande d\'informations',
            PENDING_AUTHORIZATION: 'Autorisation en attente',
            AUTHORIZED: 'Autorisé',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: 'En attente de confirmation de paiement',
            AWAITING_PAYMENT_CONFIRMATION: 'En attente de confirmation de paiement',
            REVOKED: 'Révoqué',
            WAITING_FOR_INFORMATION_FROM_ORGANIZATION: 'En attente de plus d\'informations de l\'organisation',
            WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT: 'En attente de l\'accusé de réception de l\'organisation',
            UNDER_RDA_REVIEW: 'En cours d\'examen RDA',
            WAITING_FOR_APPENDIX_H : 'En attente de l\'annexe H',
            WAITING_FOR_COLLECTION_FROM_STORE : 'En attente de retrait en magasin',
            WAITING_FOR_RDA_ACKNOWLEDGEMENT : 'Attente de l\'accusé de réception RDA',
            AWAITING_RDA_ACKNOWLEDGEMENT: 'En attente de l\'accusé de réception de la RDA',
            PENDING_APPROVAL : "Approbation en attente",
            CANCELLATION_DUE_TO_INACTIVITY:'Annulation inactive'
        },
        ja: {
            CONTRACT_CREATED_OUTSIDE_SYSTEM: 'システム外で作成された契約',
            CONTRACT_CREATED: '契約が作成されました',
            CONTRACT_UPLOADED: 'アップロードされた契約',
            UNDER_CA_REVIEW: 'CAレビュー中',
            UNDER_AC_REVIEW: 'ACレビュー中',
            WAITING_FOR_COLLECTION: 'コレクションを待っています',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: 'NGOの承認を待っています',
            WAITING_FOR_CA_ACKNOWLEDGEMENT: 'CAユーザーの確認を待っています',
            COMPLETED: '完了',
            CANCELLED: 'キャンセル',
            WAITING_FOR_APPENDIX_B: '付録Bを待っています',
            WAITING_FOR_INFORMATION_FROM_STORE: 'ストアからの情報を待っています',
            APPROVED: '承認済み',
            WAITING_FOR_INFORMATION_FROM_CA: 'CAからの詳細情報を待っています',
            REJECTED: '拒否',
            PENDING_CONTRACT_CREATION: '契約保留中',
            SUBMITTED: '提出済み',
            REQUEST_FOR_INFORMATION: '情報のリクエスト',
            PENDING_AUTHORIZATION: '承認待ち',
            AUTHORIZED: '承認済み',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: '支払いの確認待ち',
            AWAITING_PAYMENT_CONFIRMATION: '支払い確認待ち',
            REVOKED: '取り消されました',
            WAITING_FOR_INFORMATION_FROM_ORGANIZATION: '組織からの詳細情報を待っています',
            WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT: '組織の承認を待っています',
            WAITING_FOR_RDA_ACKNOWLEDGEMENT: 'RDA 確認を待っています',
            AWAITING_RDA_ACKNOWLEDGEMENT: 'RDA 確認待ち',
            PENDING_APPROVAL: '「承認待ち'
        },
        zh: {
            CONTRACT_CREATED_OUTSIDE_SYSTEM: '外部系统创建的合同',
            CONTRACT_CREATED: '合同已创建',
            CONTRACT_UPLOADED: '合同上传',
            UNDER_CA_REVIEW: '正在进行CA审核',
            UNDER_AC_REVIEW: '正在接受AC审核',
            WAITING_FOR_COLLECTION: '等待收藏',
            WAITING_FOR_NGO_ACKNOWLEDGEMENT: '等待非政府組織確認',
            WAITING_FOR_CA_ACKNOWLEDGEMENT: '等待 CA 用戶確認',
            COMPLETED: '已完成',
            CANCELLED: '取消',
            WAITING_FOR_APPENDIX_B: '等待附录 B',
            WAITING_FOR_INFORMATION_FROM_STORE: '等待來自商店的信息',
            APPROVED: '已批准',
            WAITING_FOR_INFORMATION_FROM_CA: '等待来自 CA 的更多信息',
            REJECTED: '已授权',
            PENDING_CONTRACT_CREATION: '正在创建合同',
            SUBMITTED: '已提交',
            REQUEST_FOR_INFORMATION: '请求信息',
            PENDING_AUTHORIZATION: '待授权',
            AUTHORIZED: '授权',
            AWAITING_PAYMENT_ACKNOWLEDGMENT: '正在等待付款确认',
            AWAITING_PAYMENT_CONFIRMATION: '正在等待付款确认',
            REVOKED: '已撤销',
            WAITING_FOR_INFORMATION_FROM_ORGANIZATION: '等待组织的更多信息',
            WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT: '等待组织确认',
            WAITING_FOR_RDA_ACKNOWLEDGMENT: '正在等待 RDA 确认',
            AWAITING_RDA_ACKNOWLEDGEMENT: '等待 RDA 确认',
            PENDING_APPROVAL: '等待批准'
        }
    },
}
export const donationSuccessTxt: any = {
    title: {
        en: 'Success!',
        es: '¡Éxito!',
        fr: 'Succès!',
        ja: 'Succès!',
        zh: '成功!'
    },
    content: {
        en: 'Donation request has been submitted successfully!',
        es: '¡La solicitud de donación se ha enviado correctamente!',
        fr: 'La demande de don a été soumise avec succès!',
        ja: '寄付のリクエストが正常に送信されました。',
        zh: '捐赠请求已成功提交！'
    },
    errorMessage: {
        en: 'Failed to create donation request, please try again!',
        es: 'No se pudo crear la solicitud de donación. Vuelve a intentarlo.',
        fr: 'Échec de la création de la demande de don, veuillez réessayer!',
        ja: '寄付リクエストの作成に失敗しました。もう一度お試しください。',
        zh: '无法创建捐赠请求，请重试！'
    }
}
export const tableTranslations: any = {
    boardMemberHeaders: {
        'boardMemberName': {
            en: 'Name',
            fr: 'Nom ',
            es: 'Nombre',
            ja: '氏名',
            zh: '姓名'
        },
        'boardMemberTitle': {
            en: 'Title',
            fr: 'Titre',
            es: 'Cargo',
            ja: '題名',
            zh: '標題'
        },
        'boardMemberYearOfBirth': {
            en: 'Year Of Birth',
            fr: 'Année de naissance',
            es: 'Año de Nacimiento',
            ja: '出生年',
            zh: '出生年份'
        },
        'birthPlace': {
            en: 'Place of birth',
            fr: 'Lieu de naissance',
            es: 'Lugar de nacimiento',
            ja: '出生地',
            zh: '出生地'
        },
        cityOfResidence: {
            en: 'City of residence',
            es: 'Ciudad de residencia',
            fr: 'Ville de résidence',
            ja: '居住地',
            zh: '居住城市'
        },

        stateOfResidence: {
            en: 'State of residence',
            es: 'Estado de residencia',
            fr: 'Etat de Residence',
            ja: '居住国',
            zh: '居住国'
        },
        countryOfResidence: {
            en: 'Country Of residence',
            es: 'País de residencia',
            fr: 'Pays de résidence',
            ja: '居住国',
            zh: '居住国家'
        },
        'boardMemberPlaceOfBirth': {
            en: 'Country of Residence',
            fr: 'Pays de résidence',
            es: 'País de residencia',
            ja: '居住国',
            zh: '居住國家'
        }

    },

    executiveOfficerHeaders: {
        'executiveOfficerName': {
            en: 'Name',
            fr: 'Nom ',
            es: 'Nombre',
            ja: '氏名',
            zh: '姓名'
        },
        'executiveOfficerTitle': {
            en: 'Title',
            fr: 'Titre',
            es: 'Cargo',
            ja: '題名',
            zh: '標題'
        },
        'executiveOfficerYearOfBirth': {
            en: 'Year Of Birth',
            fr: 'Année de naissance',
            es: 'Año de Nacimiento',
            ja: '出生年',
            zh: '出生年份'
        },
        'executiveOfficerPlaceOfBirth': {
            en: 'Country of Residence',
            fr: 'Pays de résidence',
            es: 'País de residencia',
            ja: '居住国',
            zh: '居住國家'
        },
        'birthPlace': {
            en: 'Place of birth',
            fr: 'Lieu de naissance',
            es: 'Lugar de nacimiento',
            ja: '出生地',
            zh: '出生地'
        },
        cityOfResidence: {
            en: 'City of residence',
            es: 'Ciudad de residencia',
            fr: 'Ville de résidence',
            ja: '居住地',
            zh: '居住城市'
        },

        stateOfResidence: {
            en: 'State of residence',
            es: 'Estado de residencia',
            fr: 'Etat de Residence',
            ja: '居住国',
            zh: '居住国'
        },
        countryOfResidence: {
            en: 'Country Of residence',
            es: 'País de residencia',
            fr: 'Pays de résidence',
            ja: '居住国',
            zh: '居住国家'
        },
        'boardMemberPlaceOfBirth': {
            en: 'Country of Residence',
            fr: 'Pays de résidence',
            es: 'País de residencia',
            ja: '居住国',
            zh: '居住國家'
        }
    },
    keyPersonsHeaders: {
        'keyPersonName': {
            en: 'Name',
            fr: 'Nom ',
            es: 'Nombre',
            ja: '氏名',
            zh: '姓名'
        },
        'keyPersonTitle': {
            en: 'Title',
            fr: 'Titre',
            es: 'Cargo',
            ja: '題名',
            zh: '標題'
        },
        'keyPersonYearOfBirth': {
            en: 'Year Of Birth',
            fr: 'Année de naissance',
            es: 'Año de Nacimiento',
            ja: '出生年',
            zh: '出生年份'
        },
        'keyPersonPlaceOfBirth': {
            en: 'Country of Residence',
            fr: 'Pays de résidence',
            es: 'País de residencia',
            ja: '居住国',
            zh: '居住國家'
        },
        'birthPlace': {
            en: 'Place of birth',
            fr: 'Lieu de naissance',
            es: 'Lugar de nacimiento',
            ja: '出生地',
            zh: '出生地'
        },
        cityOfResidence: {
            en: 'City of residence',
            es: 'Ciudad de residencia',
            fr: 'Ville de résidence',
            ja: '居住地',
            zh: '居住城市'
        },

        stateOfResidence: {
            en: 'State of residence',
            es: 'Estado de residencia',
            fr: 'Etat de Residence',
            ja: '居住国',
            zh: '居住国'
        },
        countryOfResidence: {
            en: 'Country Of residence',
            es: 'País de residencia',
            fr: 'Pays de résidence',
            ja: '居住国',
            zh: '居住国家'
        },
        'boardMemberPlaceOfBirth': {
            en: 'Country of Residence',
            fr: 'Pays de résidence',
            es: 'País de residencia',
            ja: '居住国',
            zh: '居住國家'
        }
    },
    editBtnText: {
        en: 'Edit',
        fr: 'Éditer',
        es: 'Editar',
        ja: '編集',
        zh: '編輯'
    },
    deleteBtnText: {
        en: 'Delete',
        fr: 'Effacer',
        es: 'Eliminar',
        ja: '削除',
        zh: '刪除'
    },
    editGridInvalidRowError: {
        en: 'Please correct invalid rows before proceeding.',
        fr: 'Veuillez corriger les lignes non valides avant de continuer.',
        es: 'Verifique que los datos sean correctos antes de continuar',
        ja: '続行する前に、無効な行を修正してください',
        zh: '刪除'
    }
}

export const socialImpactTranslations: any = {
    report: {
        header: {
            en: 'Donation Details',
            es: 'Detalles de la donación',
            fr: 'Détails du don',
            ja: '寄付の詳細',
            zh: '捐款詳情'
        },
        subHeader1: {
            en: 'Total number of beneficiaries by age group',
            es: 'Número total de beneficiarios por grupo de edad',
            fr: 'Nombre total de bénéficiaires par groupe d\'âge',
            ja: '年齢層別の受益者総数',
            zh: '按年齡段劃分的受益人總數'
        },
        subHeader2: {
            en: 'Breakdown of donation amounts per store this month',
            es: 'Desglose de las cantidades de donaciones por tienda este mes',
            fr: 'Répartition des montants des dons par magasin ce mois-ci',
            ja: '今月の店舗あたりの寄付額の内訳',
            zh: '本月每家商店的捐款金額明細'
        }
    },
    notificationMsg: {
        fileUploadSuccess:
        {
            en: 'File has been uploaded successfully.',
            es: 'El archivo se ha subido correctamente.',
            fr: 'Le fichier a été téléchargé avec succès.',
            ja: 'ファイルが正常にアップロードされました。',
            zh: '文件已成功上传。'
        },
        fileDownloadSuccess:
        {
            en: 'Template Excel has been downloaded successfully.',
            es: 'La plantilla de Excel se ha descargado correctamente.',
            fr: 'Le modèle Excel a été téléchargé avec succès.',
            ja: 'テンプレートExcelが正常にダウンロードされました。',
            zh: 'Excel 模板已成功下载。'
        },
        bulkUpload:
        {
            en: 'Upload beneficiaries and stores list',
            es: 'Sube la lista de beneficiarios y tiendas',
            fr: 'Téléchargez la liste des bénéficiaires et des magasins',
            ja: '受取人と店舗のリストをアップロードします',
            zh: '上传受益人和商店列表'
        },
        downloadTemplate:
        {
            en: 'downloadTemplate',
            es: 'Descargar plantilla',
            fr: 'Télécharger le modèle',
            ja: 'テンプレートをダウンロード',
            zh: '下载模板',
        },
        uploadFileSizeExceeded:
        {
            en: 'File size should not exceed 10MB. Accepted file .xlsx.',
            es: 'El tamaño del archivo no debe exceder los 10 MB. Archivo aceptado .xlsx.',
            fr: 'La taille du fichier ne doit pas dépasser 10 Mo. Fichier accepté .xlsx.',
            ja: 'ファイルサイズは10MBを超えてはなりません。受け入れられたファイル.xlsx.',
            zh: '文件大小不应超过 10MB。接受的文件 .xlsx。'
        },
        failedToDownload:
        {
            en: 'Failed to download file',
            es: 'No se pudo descargar el archivo',
            fr: 'Échec du téléchargement du fichier',
            ja: 'ファイルのダウンロードに失敗しました',
            zh: '下载文件失败',
        },
        failedToUpload:
        {
            en: 'Failed to upload the file',
            es: 'No se pudo cargar el archivo.',
            fr: 'Échec du téléchargement du fichier',
            ja: 'ファイルのアップロードに失敗しました。',
            zh: '无法上传文件。'
        }
    },
    successMsg: {
        fileUpload: {
            en: 'File has been uploaded successfully.',
            es: 'El archivo se ha subido correctamente.',
            fr: 'Le fichier a été téléchargé avec succès.',
            ja: 'ファイルが正常にアップロードされました。',
            zh: '文件已成功上传。'
        },
        fileDownload: {
            en: 'Template Excel has been downloaded successfully.',
            es: 'La plantilla de Excel se ha descargado correctamente.',
            fr: 'Le modèle Excel a été téléchargé avec succès.',
            ja: 'テンプレートExcelが正常にダウンロードされました。',
            zh: 'Excel 模板已成功下载。'
        },
        title: {
            en: 'Success',
            es: 'Éxito',
            fr: 'Succès',
            ja: '成功',
            zh: '成功'
        },
        createTxt: {
            en: 'Report has been created successfully',
            es: 'El informe se ha creado correctamente',
            fr: 'Le rapport a été créé avec succès',
            ja: 'レポートが正常に作成されました',
            zh: '報告已成功創建'
        },
        updateTxt: {
            en: 'Report has been updated successfully',
            es: 'El informe se ha actualizado correctamente',
            fr: 'Le rapport a été mis à jour avec succès',
            ja: 'レポートは正常に更新されました',
            zh: '報告已成功更新'
        }
    },
    actionMsg: {
        title: {
            en: 'Confirmation',
            es: 'Confirmación',
            fr: 'Confirmation',
            ja: '確認',
            zh: '確認書'
        },
        confirmMsg: {
            en: `Are you sure you want to submit the report?`,
            es: `¿Estás segura de que quieres enviar el informe?`,
            fr: `Voulez-vous vraiment soumettre le rapport?`,
            ja: `レポートを送信してもよろしいですか？`,
            zh: `您確定要提交報告嗎？`
        },
        warningMsg: {
            en: `You have unsaved information on the page. If you leave this page, your changes will be lost. Do you want to leave this page?`,
            es: `Tiene información sin guardar en la página. Si abandona esta página, sus cambios se perderán. ¿Quieres salir de esta página?`,
            fr: `Vous avez des informations non enregistrées sur la page. Si vous quittez cette page, vos modifications seront perdues. Voulez-vous quitter cette page?`,
            ja: `ページに未保存の情報があります。このページを離れると、変更は失われます。このページを離れますか？`,
            zh: `您在頁面上有未保存的信息。如果離開此頁面，您的更改將丟失。您要離開此頁面嗎？`
        },
        donationNotCollectedMsg: {
            en: `Are you sure you're not collected donation for the selected Month and Year?`,
            es: `¿Está seguro de que no ha recibido la donación para el mes y año seleccionados?`,
            fr: `Êtes-vous sûr de ne pas avoir collecté de dons pour le mois et l'année sélectionnés?`,
            ja: `選択した月と年の寄付を集めていませんか？`,
            zh: `您確定在選定的月份和年份沒有收到捐款嗎？`
        },
        donationNotCollectedMsgEdit: {
            en: `All the data will be cleared. Are you sure no donations were collected for this month?`,
            es: `Se borrarán todos los datos. ¿Estás seguro de que no se recolectaron donaciones para este mes?`,
            fr: `Toutes les données seront effacées. Etes-vous sûr qu'aucun don n'a été collecté ce mois-ci?`,
            ja: `すべてのデータがクリアされます。今月の寄付は集められませんでしたか？`,
            zh: `所有數據將被清除。您確定本月沒有收集任何捐款嗎？`
        },
        removeFile: {
            en: 'Are you sure you want to delete this file?',
            es: '¿Estás segura de que quieres eliminar este archivo?',
            fr: 'Voulez-vous vraiment supprimer ce fichier?',
            ja: 'このファイルを削除してもよろしいですか？',
            zh: '您確定要刪除此文件嗎？'
        },
    },
    form: {
        labels: {
            month: {
                en: 'Select Month',
                es: 'Seleccione mes',
                fr: 'Sélectionnez un mois',
                ja: '月を選択',
                zh: '選擇一個月'
            },
            year: {
                en: 'Select Year',
                es: 'Seleccionar año',
                fr: 'Sélectionnez l\'année',
                ja: '年を選択',
                zh: '選擇年份'
            },
            monthEdit: {
                en: 'Month',
                es: 'Mes',
                fr: 'Mois',
                ja: '月',
                zh: '月'
            },
            yearEdit: {
                en: 'Year',
                es: 'Año',
                fr: 'An',
                ja: '年',
                zh: '年'
            },
            submittedOn: {
                en: 'Submitted on',
                es: 'Enviado el',
                fr: 'Soumis le',
                ja: 'に提出',
                zh: '提交於'
            },
            upload: {
                en: 'Upload your file here, or ',
                es: 'Sube tu archivo aquí, o',
                fr: 'Téléchargez votre fichier ici, ou',
                ja: 'ここにあなたのファイルをアップロードしますか、',
                zh: '这里上传您的文件，或'
            },
            browse: {
                en: 'browse',
                es: 'vistazo',
                fr: 'Feuilleter',
                ja: 'ブラウズ',
                zh: '瀏覽'
            },
            donationNotCollected: {
                en: 'No donations were collected for this month',
                es: 'No se recolectaron donaciones para este mes',
                fr: 'Aucun don n\'a été collecté ce mois- ci',
                ja: '今月の寄付はありませんでした',
                zh: '本月未收集任何捐款'
            },
            summuryTxt: {
                en: 'Enter Social Impact summary(Please describe the activities)',
                es: 'Ingrese el resumen de Impacto Social(Describa las actividades)',
                fr: 'Entrez le résumé de l\'impact social(veuillez décrire les activités)',
                ja: '社会的影響の概要を入力してください（活動について説明してください',
                zh: '輸入社會影響摘要（請描述活動'
            },
            summaryPlaceHolder: {
                en: 'Type summary',
                es: 'Resumen de tipos',
                fr: 'Résumé du type',
                ja: 'タイプの概要',
                zh: '類型匯總'
            },
            children: {
                en: 'Children aged (0-12)',
                es: 'Niños de (0-12 años)',
                fr: 'Enfants âgés(0- 12)',
                ja: '(0-12）歳の子供',
                zh: '(0-12歲）兒童'
            },
            younAdults: {
                en: 'Young Adults (13-19)',
                es: 'Adultos jóvenes (13-19)',
                fr: 'Jeunes adultes (13 à 19 ans)',
                ja: 'ヤングアダルト（13-19）',
                zh: '年輕人（13-19歲）'
            },
            adults: {
                en: 'Adults (20-59)',
                es: 'Adultos (20-59)',
                fr: 'Adultes (20-59)',
                ja: '大人（20-59）',
                zh: '成人（20-59）'
            },
            seniors: {
                en: 'Seniors (60+)',
                es: 'Mayores (60+)',
                fr: 'Aînés(60+)',
                ja: '高齢者（60歳以上）',
                zh: '老年人（60歲以上）'
            },
            totalDirectBnfcyCount: {
                en: 'Total Direct Beneficiaries',
                es: 'Beneficiarios directos totales',
                fr: 'Bénéficiaires directs totaux',
                ja: '直接受益者の合計',
                zh: '直接受益人總數'
            },
            noOfFmly: {
                en: 'Number of Families',
                es: 'Numero de familias',
                fr: 'Nombre de familles',
                ja: '家族の数',
                zh: '家庭數量'
            },
            womenBnfcy: {
                en: 'Women beneficiaries',
                es: 'Mujeres beneficiarias',
                fr: 'Femmes bénéficiaires',
                ja: '女性の受益者',
                zh: '婦女受益人'
            },
            totalIndirectBnfcyCount: {
                en: 'Total Indirect Beneficiaries',
                es: 'Beneficiarios indirectos totales',
                fr: 'Total des bénéficiaires indirects',
                ja: '間接受益者の合計',
                zh: '間接受益人總數'
            },
            facilityNo: {
                en: 'Facility #',
                es: 'Instalaciones #',
                fr: 'Établissement #',
                ja: '施設 ＃',
                zh: '設施編號'
            },
            noOfCartons: {
                en: 'Number of Cartons',
                es: 'Numero de cartones',
                fr: 'Nombre de cartons',
                ja: 'カートンの数',
                zh: '紙箱數量'
            },
            amtReceived: {
                en: 'Amount received in kgs',
                es: 'Cantidad recibida en kgs',
                fr: 'Montant reçu en kg',
                ja: '受け取った量（kg',
                zh: '收到的金額（公斤'
            },
            totalFclty: {
                en: 'Total facilities #',
                es: 'Total de instalaciones #',
                fr: 'Nombre total d\'installations',
                ja: '総施設数＃',
                zh: '設施總數'
            },
            totalCartons: {
                en: 'Total number of cartons',
                es: 'Número total de cajas',
                fr: 'Nombre total de cartons',
                ja: 'カートンの総数',
                zh: '紙箱總數'
            },
            totalAmountReceived: {
                en: 'Total amount received in kgs',
                es: 'Cantidad total recibida en kgs',
                fr: 'Montant total reçu en kg',
                ja: '受け取った合計金額（kg）',
                zh: '收到的總金額（千克'
            },
            receipt: {
                en: 'Receipt upload',
                es: 'Carga de recibo',
                fr: 'Téléchargement du reçu',
                ja: '領収書のアップロード',
                zh: '收據上傳'
            },
            uploadedFile: {
                en: 'Attached File',
                es: 'Archivo adjunto',
                fr: 'Pièce jointe',
                ja: '添付ファイル',
                zh: '附件文件'
            },
            receiptDesc: {
                en: 'Receipt description',
                es: 'Descripción del recibo',
                fr: 'Description du reçu',
                ja: '領収書の説明',
                zh: '收據說明'
            }
        },
        btn: {
            bulkUpload: {
                en: 'Upload beneficiaries and stores list',
                es: 'Sube la lista de beneficiarios y tiendas',
                fr: 'Téléchargez la liste des bénéficiaires et des magasins',
                ja: '受取人と店舗のリストをアップロードします',
                zh: '上传受益人和商店列表'
            },
            downloadTemplate: {
                en: 'Download template',
                es: 'Descargar plantilla',
                fr: 'Télécharger le modèle',
                ja: 'テンプレートをダウンロード',
                zh: '下载模板'
            },
            submit: {
                en: 'Submit Report',
                es: 'Enviar reporte',
                fr: 'Soumettre le rapport',
                ja: 'レポートを送信する',
                zh: '提交報告'
            },
            cancel: {
                en: 'CANCEL',
                es: 'CANCELAR',
                fr: 'ANNULER',
                ja: 'キャンセル',
                zh: '取消'
            },
            back: {
                en: 'GO BACK TO LIST',
                es: 'VOLVER A LA LISTA',
                fr: 'RETOUR À LA LISTE',
                ja: 'リストに戻る',
                zh: '返回列表'
            },
            addFacility: {
                en: '+ ADD FACILITY',
                es: '+ AÑADIR INSTALACIÓN',
                fr: '+ AJOUTER UNE INSTALLATION',
                ja: '+施設を追加する',
                zh: '+新增設施'
            },
            yes: {
                en: 'Yes',
                es: 'si',
                fr: 'Oui',
                ja: 'はい',
                zh: '是'
            },
            no: {
                en: 'No',
                es: 'No',
                fr: 'Non',
                ja: '番号',
                zh: '沒有'
            },
        },
        validationMsg: {
            uploadFileSizeExceeded: {
                en: 'File size should not exceed 10MB. Accepted file .xlsx.',
                es: 'El tamaño del archivo no debe exceder los 10 MB. Archivo aceptado .xlsx.',
                fr: 'La taille du fichier ne doit pas dépasser 10 Mo. Fichier accepté .xlsx.',
                ja: 'ファイルサイズは10MBを超えてはなりません。受け入れられたファイル.xlsx。',
                zh: '文件大小不应超过 10MB。接受的文件 .xlsx。'
            },
            required: {
                en: 'This field is required',
                es: 'este campo es requerido',
                fr: 'Ce champ est requis',
                ja: 'この項目は必須です',
                zh: '這是必填欄'
            },
            fileSize: {
                en: 'File size should not be more than 5MB',
                es: 'El tamaño del archivo no debe superar los 5 MB',
                fr: 'La taille du fichier ne doit pas dépasser 5 Mo',
                ja: 'ファイルサイズは5MBを超えてはなりません',
                zh: '檔案大小不得超過5MB'
            },
            fileFormat: {
                en: 'Allowed only .pdf, .doc/docx, .png, .jpg, .jpeg and .txt file type',
                es: 'Solo se permite el tipo de archivo .pdf, .doc / docx, .png, .jpg, .jpeg y .txt',
                fr: 'Autorisé uniquement les types de fichiers .pdf, .doc / docx, .png, .jpg, .jpeg et .txt',
                ja: '許可されるのは、.pdf、.doc / docx、.png、.jpg、.jpeg、および.txtファイルタイプのみです',
                zh: '僅允許使用.pdf，.doc / docx，.png，.jpg，.jpeg和.txt文件類型'
            },
            duplicateStoreNo: {
                en: 'Duplicate facility# :',
                es: 'Número de instalación duplicado:',
                fr: 'Dupliquer le numéro de l\'établissement:',
                ja: '重複施設＃：',
                zh: '設施重複＃：'
            },
            numberRange: {
                en: 'Number must be between 0 and 999,999',
                es: 'El número debe estar entre 0 y 999,999',
                fr: 'Le nombre doit être compris entre 0 et 999 999',
                ja: '番号は0から999,999の間でなければなりません',
                zh: '數字必須介於0到999,999之間'
            },
            numbersOnly: {
                en: 'This field must contain only numbers',
                es: 'Este campo debe contener solo números',
                fr: 'Ce champ ne doit contenir que des chiffres',
                ja: 'このフィールドには数字のみを含める必要があります',
                zh: '此字段只能包含數字'
            },
            amountReceived: {
                en: 'Amount received in kgs should contain max of 6 digits with 3 digits after decimal',
                es: 'La cantidad recibida en kg debe contener un máximo de 6 dígitos con 3 dígitos después del decimal',
                fr: 'Le montant reçu en kg doit contenir au maximum 6 chiffres avec 3 chiffres après la virgule',
                ja: 'キログラムで受け取る金額には、最大6桁、小数点以下3桁が含まれている必要があります',
                zh: '以公斤為單位的收款金額應最多包含6位數字，小數點後為3位數字'
            },
            month: {
                en: 'Report Month cannot be future value',
                es: 'El mes del informe no puede ser un valor futuro',
                fr: 'Le mois du rapport ne peut pas être une valeur future',
                ja: 'レポート月を将来価値にすることはできません',
                zh: '報告月份不能為終值'
            },
            summaryError: {
                en: 'This field has a limit of 4000 characters',
                es: 'Este campo tiene un límite de 4000 caracteres',
                fr: 'Ce champ a une limite de 4000 caractères',
                ja: 'このフィールドには4000文字の制限があります',
                zh: '該字段的上限為4000個字符'
            },
            fileDescError: {
                en: 'This field has a limit of 2000 characters',
                es: 'Este campo tiene un límite de 2000 caracteres',
                fr: 'Ce champ a une limite de 2000 caractères',
                ja: 'このフィールドには2000文字の制限があります',
                zh: '該字段的上限為2000個字符'
            }
        }
    },
    months: [
        {
            value: 'January',
            label: {
                en: 'January',
                es: 'enero',
                fr: 'janvier',
                ja: '1月',
                zh: '一月'
            }
        },
        {
            value: 'February',
            label: {
                en: 'February',
                es: 'febrero',
                fr: 'février',
                ja: '2月',
                zh: '二月'
            }
        },
        {
            value: 'March',
            label: {
                en: 'March',
                es: 'marzo',
                fr: 'Mars',
                ja: '行進',
                zh: '遊行'
            }
        },
        {
            value: 'April',
            label: {
                en: 'April',
                es: 'abril',
                fr: 'avril',
                ja: '4月',
                zh: '四月'
            }
        },
        {
            value: 'May',
            label: {
                en: 'May',
                es: 'Mayo',
                fr: 'Mai',
                ja: '五月',
                zh: '可能'
            }
        },
        {
            value: 'June',
            label: {
                en: 'June',
                es: 'junio',
                fr: 'juin',
                ja: '六月',
                zh: '六月'
            }
        },
        {
            value: 'July',
            label: {
                en: 'July',
                es: 'julio',
                fr: 'juillet',
                ja: '7月',
                zh: '七月'
            }
        },
        {
            value: 'August',
            label: {
                en: 'August',
                es: 'agosto',
                fr: 'août',
                ja: '8月',
                zh: '八月'
            }
        },
        {
            value: 'September',
            label: {
                en: 'September',
                es: 'septiembre',
                fr: 'septembre',
                ja: '9月',
                zh: '九月'
            }
        },
        {
            value: 'October',
            label: {
                en: 'October',
                es: 'octubre',
                fr: 'octobre',
                ja: '10月',
                zh: '十月'
            }
        },
        {
            value: 'November',
            label: {
                en: 'November',
                es: 'noviembre',
                fr: 'novembre',
                ja: '11月',
                zh: '十一月'
            }
        },
        {
            value: 'December',
            label: {
                en: 'December',
                es: 'diciembre',
                fr: 'décembre',
                ja: '12月',
                zh: '十二月'
            }
        }
    ],
    socialImpactMonths: [
        {
            value: 1,
            label: {
                en: 'January',
                es: 'enero',
                fr: 'janvier',
                ja: '1月',
                zh: '一月'
            }
        },
        {
            value: 2,
            label: {
                en: 'February',
                es: 'febrero',
                fr: 'février',
                ja: '2月',
                zh: '二月'
            }
        },
        {
            value: 3,
            label: {
                en: 'March',
                es: 'marzo',
                fr: 'Mars',
                ja: '行進',
                zh: '遊行'
            }
        },
        {
            value: 4,
            label: {
                en: 'April',
                es: 'abril',
                fr: 'avril',
                ja: '4月',
                zh: '四月'
            }
        },
        {
            value: 5,
            label: {
                en: 'May',
                es: 'Mayo',
                fr: 'Mai',
                ja: '五月',
                zh: '可能'
            }
        },
        {
            value: 6,
            label: {
                en: 'June',
                es: 'junio',
                fr: 'juin',
                ja: '六月',
                zh: '六月'
            }
        },
        {
            value: 7,
            label: {
                en: 'July',
                es: 'julio',
                fr: 'juillet',
                ja: '7月',
                zh: '七月'
            }
        },
        {
            value: 8,
            label: {
                en: 'August',
                es: 'agosto',
                fr: 'août',
                ja: '8月',
                zh: '八月'
            }
        },
        {
            value: 9,
            label: {
                en: 'September',
                es: 'septiembre',
                fr: 'septembre',
                ja: '9月',
                zh: '九月'
            }
        },
        {
            value: 10,
            label: {
                en: 'October',
                es: 'octubre',
                fr: 'octobre',
                ja: '10月',
                zh: '十月'
            }
        },
        {
            value: 11,
            label: {
                en: 'November',
                es: 'noviembre',
                fr: 'novembre',
                ja: '11月',
                zh: '十一月'
            }
        },
        {
            value: 12,
            label: {
                en: 'December',
                es: 'diciembre',
                fr: 'décembre',
                ja: '12月',
                zh: '十二月'
            }
        }
    ],
    monthText: {
        January: {
            value: 'January',
            label: {
                en: 'January',
                es: 'Enero',
                fr: 'Janvier',
                ja: '1月',
                zh: '一月'
            }
        },
        February: {
            value: 'February',
            label: {
                en: 'February',
                es: 'Febrero',
                fr: 'Février',
                ja: '2月',
                zh: '二月'
            }
        },
        March: {
            value: 'March',
            label: {
                en: 'March',
                es: 'Marzo',
                fr: 'Mars',
                ja: '行進',
                zh: '遊行'
            }
        },
        April: {
            value: 'April',
            label: {
                en: 'April',
                es: 'Abril',
                fr: 'Avril',
                ja: '4月',
                zh: '四月'
            }
        },
        May: {
            value: 'May',
            label: {
                en: 'May',
                es: 'Mayo',
                fr: 'Mai',
                ja: '五月',
                zh: '可能'
            }
        },
        June: {
            value: 'June',
            label: {
                en: 'June',
                es: 'Junio',
                fr: 'Juin',
                ja: '六月',
                zh: '六月'
            }
        },
        July: {
            value: 'July',
            label: {
                en: 'July',
                es: 'Julio',
                fr: 'Juillet',
                ja: '7月',
                zh: '七月'
            }
        },
        August: {
            value: 'August',
            label: {
                en: 'August',
                es: 'Agosto',
                fr: 'Août',
                ja: '8月',
                zh: '八月'
            }
        },
        September: {
            value: 'September',
            label: {
                en: 'September',
                es: 'Septiembre',
                fr: 'Septembre',
                ja: '9月',
                zh: '九月'
            }
        },
        October: {
            value: 'October',
            label: {
                en: 'October',
                es: 'Octubre',
                fr: 'Octobre',
                ja: '10月',
                zh: '十月'
            }
        },
        November: {
            value: 'November',
            label: {
                en: 'November',
                es: 'Noviembre',
                fr: 'Novembre',
                ja: '11月',
                zh: '十一月'
            }
        },
        December: {
            value: 'December',
            label: {
                en: 'December',
                es: 'Diciembre',
                fr: 'Décembre',
                ja: '12月',
                zh: '十二月'
            }
        }
    },
    momentLocales: {
        en: 'en-gb',
        es: 'es',
        fr: 'fr-ca',
        ja: 'ja',
        zh: 'zh-cn'
    }
}

export const taxIdFormat: any = {
    AR: /^[0-9]{11}$/,
    BR: '',
    BW: '',
    CA: /^[0-9]{9}[A-Z]{2}[0-9]{4}$/,
    CL: /^[0-9]{8}(?:-)(\d|[K]){1}$/,
    CN: '',
    CR: '',
    GB: '',
    GH: '',
    GT: '',
    HN: '',
    IN: '',
    JP: '',
    KE: '',
    LS: '',
    MW: '',
    MX: /^[A-Z]{3}[0-9A-Z]{9}$/,
    MZ: '',
    NA: '',
    NG: '',
    NI: '',
    SV: '',
    SZ: '',
    TZ: '',
    UG: '',
    ZA: '',
    ZM: ''
}
export const taxIdPlaceholderTxt: any = {
    AR: '20123456987',
    CA: '123456789RR0001',
    MX: 'IBP590728881',
    CL: '12345678-1, or 12345678-K'
}
export const coutryCodeToCountryName: any = {
    //'es_AR': 'Argentina',
    'en_CA': 'Canada(English)',
    'fr_CA': 'Canada(Français)',
    'es_CL': 'Chile',
    'es_CR': 'Costa Rica',
    'es_SV': 'El Salvador',
    'es_GT': 'Guatemala',
    'es_HN': 'Honduras',
    'es_MX': 'México',
    'es_NI': 'Nicaragua',
    'en_GB': 'United Kingdom',
    'en_ZA': 'South Africa',
    'zh_CN': 'China(中文)',
    'en_IN': 'India',
    'ja_JP': 'Japan(日本語)',
    'en_BW': 'Botswana',
    'en_GH': 'Ghana',
    'en_KE': 'Kenya',
    'en_LS': 'Lesotho',
    'en_MW': 'Malawi',
    'en_MZ': 'Mozambique',
    'en_NA': 'Namibia',
    'en_NG': 'Nigeria',
    'en_SZ': 'Swaziland',
    'en_TZ': 'Tanzania',
    'en_UG': 'Uganda',
    'en_ZM': 'Zambia',
}

export const FALLBACK_LNG = 'en';
export const UNKNOWN_ERROR = 'Unknown Error';
export const NO_DATA_FOUND = '- - -';
export const DEV_LOAD_PATH = '/locales/{{lng}}.json';
export const PROD_LOAD_PATH = '/GlobalDonations/gdms/static/locales/{{lng}}.json';
export const languageTranslations: any = {
    en: {
        en: 'English',
        es: 'Inglés',
        fr: 'Anglais',
        ja: '英語',
        zh: '英語'
    },
    es: {
        en: 'Spanish',
        es: 'Español',
        fr: 'Espanol',
        ja: 'スペイン語',
        zh: '西班牙語'
    },
    fr: {
        en: 'French',
        es: 'Francés',
        fr: 'Français',
        ja: 'フランス語',
        zh: '法語'
    },
    ja: {
        en: 'Japanese',
        es: 'Japonés',
        fr: 'Japonais',
        ja: '日本語',
        zh: '日本人'
    },
    zh: {
        en: 'Chinese',
        es: 'Chino',
        fr: 'Chinois',
        ja: '中国語',
        zh: '中國人'
    }
}

export const countryCodeTranslations: any[] = [
    {
        code: 'AR',
        label: {
            en: 'Argentina',
            es: 'Argentina',
            fr: 'Argentina',
            ja: 'Argentina',
            zh: 'Argentina'
        }
    },
    {
        code: 'CA',
        label: {
            en: 'Canada',
            es: 'Canada',
            fr: 'Canada',
            ja: 'Canada',
            zh: 'Canada'
        }
    },
    {
        code: 'CL',
        label: {
            en: 'Chile',
            es: 'Chile',
            fr: 'Chile',
            ja: 'Chile',
            zh: 'Chile'
        }
    },
    {
        code: 'CR',
        label: {
            en: 'Costa Rica',
            es: 'Costa Rica',
            fr: 'Costa Rica',
            ja: 'Costa Rica',
            zh: 'Costa Rica'
        }
    },
    {
        code: 'SV',
        label: {
            en: 'El Salvador',
            es: 'El Salvador',
            fr: 'El Salvador',
            ja: 'El Salvador',
            zh: 'El Salvador'
        }
    },
    {
        code: 'GT',
        label: {
            en: 'Guatemala',
            es: 'Guatemala',
            fr: 'Guatemala',
            ja: 'Guatemala',
            zh: 'Guatemala'
        }
    },
    {
        code: 'HN',
        label: {
            en: 'Honduras',
            es: 'Honduras',
            fr: 'Honduras',
            ja: 'Honduras',
            zh: 'Honduras'
        }
    },
    {
        code: 'MX',
        label: {
            en: 'Mexico',
            es: 'México',
            fr: 'México',
            ja: 'México',
            zh: 'México'
        }
    },
    {
        code: 'NI',
        label: {
            en: 'Nicaragua',
            es: 'Nicaragua',
            fr: 'Nicaragua',
            ja: 'Nicaragua',
            zh: 'Nicaragua'
        }
    },
    {
        code: 'GB',
        label: {
            en: 'United Kingdom',
            es: 'United Kingdom',
            fr: 'United Kingdom',
            ja: 'United Kingdom',
            zh: 'United Kingdom'
        }
    },
    {
        code: 'CN',
        label: {
            en: 'China',
            es: 'China',
            fr: 'China',
            ja: 'China',
            zh: 'China'
        }
    },
    {
        code: 'IN',
        label: {
            en: 'India',
            es: 'India',
            fr: 'India',
            ja: 'India',
            zh: 'India'
        }
    },
    {
        code: 'JP',
        label: {
            en: 'Japan',
            es: 'Japan',
            fr: 'Japan',
            ja: 'Japan',
            zh: 'Japan'
        }
    },
    {
        code: 'BW',
        label: {
            en: 'Botswana',
            es: 'Botswana',
            fr: 'Botswana',
            ja: 'Botswana',
            zh: 'Botswana'
        }
    },
    {
        code: 'GH',
        label: {
            en: 'Ghana',
            es: 'Ghana',
            fr: 'Ghana',
            ja: 'Ghana',
            zh: 'Ghana'
        }
    },
    {
        code: 'KE',
        label: {
            en: 'Kenya',
            es: 'Kenya',
            fr: 'Kenya',
            ja: 'Kenya',
            zh: 'Kenya'
        }
    },
    {
        code: 'LS',
        label: {
            en: 'Lesotho',
            es: 'Lesotho',
            fr: 'Lesotho',
            ja: 'Lesotho',
            zh: 'Lesotho'
        }
    },
    {
        code: 'MW',
        label: {
            en: 'Malawi',
            es: 'Malawi',
            fr: 'Malawi',
            ja: 'Malawi',
            zh: 'Malawi'
        }
    },
    {
        code: 'MZ',
        label: {
            en: 'Mozambique',
            es: 'Mozambique',
            fr: 'Mozambique',
            ja: 'Mozambique',
            zh: 'Mozambique'
        }
    },
    {
        code: 'NA',
        label: {
            en: 'Namibia',
            es: 'Namibia',
            fr: 'Namibia',
            ja: 'Namibia',
            zh: 'Namibia'
        }
    },
    {
        code: 'NG',
        label: {
            en: 'Nigeria',
            es: 'Nigeria',
            fr: 'Nigeria',
            ja: 'Nigeria',
            zh: 'Nigeria'
        }
    },
    {
        code: 'ZA',
        label: {
            en: 'South Africa',
            es: 'South Africa',
            fr: 'South Africa',
            ja: 'South Africa',
            zh: 'South Africa'
        }
    },
    {
        code: 'SZ',
        label: {
            en: 'Swaziland',
            es: 'Swaziland',
            fr: 'Swaziland',
            ja: 'Swaziland',
            zh: 'Swaziland'
        }
    },
    {
        code: 'TZ',
        label: {
            en: 'Tanzania',
            es: 'Tanzania',
            fr: 'Tanzania',
            ja: 'Tanzania',
            zh: 'Tanzania'
        }
    },
    {
        code: 'UG',
        label: {
            en: 'Uganda',
            es: 'Uganda',
            fr: 'Uganda',
            ja: 'Uganda',
            zh: 'Uganda'
        }
    },
    {
        code: 'ZM',
        label: {
            en: 'Zambia',
            es: 'Zambia',
            fr: 'Zambia',
            ja: 'Zambia',
            zh: 'Zambia'
        }
    }
]

export const orgUserManagementTranslations: any = {
    addNewOrgUserLabel: {
        en: 'Add New User',
        es: 'Agregar Nuevo Usuario',
        fr: 'Ajouter Un Nouvel Utilisateur',
        ja: '新しいユーザーを追加',
        zh: '创建新的'
    },
    editOrgUserLabel: {
        en: 'Edit User',
        es: 'Editar Usuario',
        fr: 'Modifier L\'utilisateur',
        ja: 'ユーザーの編集',
        zh: '修改用戶'
    },
    backBtnLabel: {
        en: 'Back',
        es: 'atrás',
        fr: 'Retour',
        ja: 'バック',
        zh: '添加新用戶'
    },
    primaryOrgUserLabel: {
        en: 'Primary User',
        es: 'Usuario Primario',
        fr: 'Utilisateur principal',
        ja: 'プライマリユーザー',
        zh: '主要使用者'
    },
    additionalOrgUserLabel: {
        en: 'Additional User',
        es: 'Usuario Adicional',
        fr: 'Utilisateur Supplémentaire',
        ja: '追加ユーザー',
        zh: '附加用戶'
    },
    edit: {
        en: 'Edit',
        es: 'Editar',
        fr: 'Éditer',
        ja: '編集',
        zh: '編輯'
    },
    remove: {
        en: 'Remove',
        es: 'Eliminar',
        fr: 'Supprimer',
        ja: '削除する',
        zh: '去掉'
    },
    sendTemporaryPassword: {
        en: 'Send Temporary Password',
        es: 'Enviar Contraseña Temporal',
        fr: 'Envoyer Un Mot De Passe Temporaire',
        ja: '一時パスワードを送信する',
        zh: '發送臨時密碼'
    },
    setAsPrimary: {
        en: 'Set As Primary User',
        es: 'Establecer Como Usuario Principal',
        fr: 'Définir Comme Utilisateur Principal',
        ja: 'プライマリユーザーとして設定',
        zh: '設置為主要用戶'
    },
    seeActions: {
        en: 'See Actions',
        es: 'Ver Acciones',
        fr: 'Voir Les Actions',
        ja: 'アクションを参照してください',
        zh: '查看動作'
    },
    submit: {
        en: 'Submit',
        es: 'Enviar',
        fr: 'Soumettre',
        ja: '参加する',
        zh: '提交'
    },
    modal: {
        title: {
            remove: {
                en: 'Remove User',
                es: 'Eliminar Usuario',
                fr: 'Supprimer L\'utilisateur',
                ja: 'ユーザーを削除する',
                zh: '刪除用戶'
            },
            sendTemporaryPassword: {
                en: 'Send Temporary Password',
                es: 'Enviar Contraseña Temporal',
                fr: 'Envoyer Un Mot De Passe Temporaire',
                ja: '一時パスワードを送信する',
                zh: '發送臨時密碼'
            },
            setAsPrimary: {
                en: 'Set As Primary User',
                es: 'Establecer Como Usuario Principal',
                fr: 'Définir Comme Utilisateur Principal',
                ja: 'プライマリユーザーとして設定',
                zh: '設置為主要用戶'
            }
        },
        message: {
            removeWarningMessage: {
                en: 'This action will remove the user\'s access to this organization. Do you wish to remove this user ?',
                es: 'Esta acción eliminará el acceso del usuario a esta organización. ¿Deseas eliminar a este usuario?',
                fr: 'Cette action supprimera l\'accès de l\'utilisateur à cette organization. Souhaitez-vous supprimer cet utilisateur?',
                ja: 'このアクションにより、この組織へのユーザーのアクセスが削除されます。このユーザーを削除しますか？',
                zh: '此操作將刪除用戶對該組織的訪問權限。您要刪除此用戶嗎？'
            },
            sendTemporaryPasswordWarningMessage: {
                en: 'This action will reset the user\'s password to a temporary password. Do you wish to reset the password and send the new email to the user ?',
                es: 'Esta acción restablecerá la contraseña del usuario a una contraseña temporal. ¿Desea restablecer la contraseña y enviar el nuevo correo electrónico al usuario?',
                fr: 'Cette action réinitialisera le mot de passe de l\'utilisateur à un mot de passe temporaire. Souhaitez-vous réinitialiser le mot de passe et envoyer le nouvel e-mail à l\'utilisateur?',
                ja: 'このアクションにより、ユーザーのパスワードが一時的なパスワードにリセットされます。パスワードをリセットして、新しいメールをユーザーに送信しますか？',
                zh: '此操作會將用戶的密碼重置為臨時密碼。您是否希望重設密碼並將新電子郵件發送給用戶？'
            },
            setAsPrimaryWarningMessage: {
                en: 'This action will make the user as the primary org user. Do you wish to make this user as primary org user ?',
                es: 'Esta acción convertirá al usuario en el usuario principal de la organización. ¿Desea que este usuario sea el usuario principal de la organización?',
                fr: 'Cette action fera de l\'utilisateur l\'utilisateur principal de l\'organization. Souhaitez-vous faire de cet utilisateur l\'utilisateur principal de l\'organization?',
                ja: 'このアクションにより、ユーザーはプライマリ組織ユーザーになります。このユーザーをプライマリ組織ユーザーにしますか？',
                zh: '此操作將使該用戶成為主要的組織用戶。您是否希望將此用戶設置為主要組織用戶？'
            }
        }
    },
    resetPasswordModal: {
        title: {
            en: 'Reset password',
            es: 'Restablecer la contraseña',
            fr: 'Réinitialiser le mot de passe',
            ja: 'パスワードを再設定する',
            zh: '重设密码'
        },
        content: {
            en: 'Please input new password:',
            es: 'Ingrese una nueva contraseña:',
            fr: 'Veuillez saisir un nouveau mot de passe:',
            ja: '新しいパスワードを入力してください:',
            zh: '请输入新密码:'
        },
        yesButtonText: {
            en: 'Save and re-login',
            es: 'Guardar y volver a iniciar sesión',
            fr: 'Enregistrez et reconnectez-vous',
            ja: '保存して再ログインします',
            zh: '保存并重新登录'
        },
        password: {
            en: 'New password',
            es: 'Nueva contraseña',
            fr: 'Nouveau mot de passe',
            ja: '新しいパスワード',
            zh: '新密码'
        },
        confirmPassword: {
            en: 'Confirm password',
            es: 'Confirmar Contraseña',
            fr: 'Confirmez le mot de passe',
            ja: 'パスワードを認証する',
            zh: '确认密码'
        },
        msgPasswordNotMatching: {
            en: 'Passwords do not match',
            es: 'Las contraseñas no coinciden',
            fr: 'Les mots de passe ne correspondent pas',
            ja: 'パスワードが一致していません',
            zh: '密码不匹配'
        },
        msgInValidPassword: {
            en: 'Please enter a valid password',
            es: 'Por favor introduce una contraseña válida',
            fr: 'Entrer un mot de passe valide s\'il vous plait',
            ja: '有効なパスワードを入力して下さい',
            zh: '请输入有效密码'
        },
        lblPasswordType: {
            en: 'Password should contain 1 capital letter, 1 number and 1 special character and minimum 8 character',
            es: 'La contraseña debe contener 1 letra mayúscula, 1 número y 1 carácter especial',
            fr: 'Le mot de passe doit contenir 1 lettre majuscule, 1 chiffre et 1 caractère spécial',
            ja: 'パスワードには、大文字1文字、数字1文字、特殊文字1文字を含める必要があります',
            zh: '密码应包含1个大写字母，1个数字和1个特殊字符'
        }
    },
    form: {
        labels: {
            name: {
                en: 'Name',
                es: 'Nombre',
                fr: 'Nom',
                ja: '名前',
                zh: '名稱'
            },
            title: {
                en: 'Title',
                es: 'Título',
                fr: 'Titre',
                ja: '題名',
                zh: '标题'
            },
            emailId: {
                en: 'Email ID',
                es: 'Identificación De Correo',
                fr: 'ID E-mail',
                ja: '電子メールID',
                zh: '電子郵件ID'
            },
            birthYear: {
                en: 'Year Of Birth',
                es: 'Año De Nacimiento',
                fr: 'Année De Naissance',
                ja: '生年',
                zh: '出生年份'
            },
            countryOfResidence: {
                en: 'Country Of Residence',
                es: 'País De Residencia',
                fr: 'Pays De Résidence',
                ja: '居住国',
                zh: '居住國家'
            },
            telephone: {
                en: 'Telephone',
                es: 'Teléfono',
                fr: 'Téléphone',
                ja: '電話',
                zh: '電話'
            },
            prefLanguage: {
                en: 'Select Preferred Language',
                es: 'Seleccione El Idioma Preferido',
                fr: 'Sélectionnez La Langue Préférée',
                ja: '優先言語を選択',
                zh: '選擇首選語言'
            }
        },
        validationMsg: {
            invalidEmail: {
                en: 'Please enter a valid email ID',
                es: 'Ingrese un ID de correo electrónico válido',
                fr: 'Veuillez saisir une adresse e-mail valide',
                ja: '有効なメールIDを入力してください',
                zh: '請輸入有效的電子郵件ID'
            },
            nameRequired: {
                en: 'Please enter a name',
                es: 'Por favor ingrese un nombre',
                fr: 'Veuillez saisir un nom',
                ja: '名前を入力してください',
                zh: '請輸入一個名字'
            },
            titleRequired: {
                en: 'Please enter a Title',
                es: 'Por favor ingrese un Título',
                fr: 'Veuillez saisir un titre',
                ja: 'タイトルを入力してください',
                zh: '请输入标题'
            },
            emailRequired: {
                en: 'Please enter an email ID',
                es: 'Ingrese un ID de correo electrónico',
                fr: 'Veuillez saisir un identifiant de messagerie',
                ja: 'メールIDを入力してください',
                zh: '請輸入一個電子郵件ID'
            },
            birthYearRequired: {
                en: 'Please enter the year of birth',
                es: 'Ingrese el año de nacimiento',
                fr: 'Veuillez saisir l\'année de naissance',
                ja: '生年月日を入力してください',
                zh: '請輸入出生年份'
            },
            invalidBirthYear: {
                en: 'Please enter a valid year of birth',
                es: 'Ingrese un año de nacimiento válido',
                fr: 'Veuillez saisir une année de naissance valide',
                ja: '有効な生年月日を入力してください',
                zh: '請輸入有效的出生年份'
            },
            countryOfResidenceRequired: {
                en: 'Please enter the country of residence',
                es: 'Ingrese el país de residencia',
                fr: 'Veuillez entrer le pays de résidence',
                ja: '居住国を入力してください',
                zh: '請輸入居住國家'
            },
            telephoneRequired: {
                en: 'Please enter the telephone number',
                es: 'Por favor ingrese el numero de telefono',
                fr: 'Veuillez saisir le numéro de téléphone',
                ja: '電話番号を入力してください',
                zh: '請輸入電話號碼'
            },
            prefLanguageRequired: {
                en: 'Please select a language',
                es: 'Por favor, seleccione un idioma',
                fr: 'Veuillez sélectionner une langue',
                ja: '言語を選択してください',
                zh: '請選擇一種語言'
            },
        }
    },
    orgUserGenericError: {
        en: 'Something went wrong. Please try again later',
        es: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde',
        fr: 'Un problème est survenu. Veuillez réessayer plus tard',
        ja: '何かがうまくいかなかった。後でもう一度やり直してください',
        zh: '出問題了。請稍後再試'
    },
    addOrgUserSuccessMessage: {
        en: 'Successfully added new org user',
        es: 'Nuevo usuario de organización agregado exitosamente',
        fr: 'Nouvel utilisateur de l\'organization ajouté avec succès',
        ja: '新しい組織ユーザーが正常に追加されました',
        zh: '成功添加新的組織用戶'
    },
    editOrgUserSuccessMessage: {
        en: 'Successfully edited org user',
        es: 'Usuario de la organización editado correctamente',
        fr: 'Utilisateur de l\'organization modifié avec succès',
        ja: '組織ユーザーの編集に成功しました',
        zh: '成功編輯組織用戶'
    },
    removeOrgUserSuccessMessage: {
        en: 'Successfully removed org user',
        es: 'Usuario de la organización eliminado correctamente',
        fr: 'L\'utilisateur de l\'organization a bien été supprimé',
        ja: '組織ユーザーが正常に削除されました',
        zh: '成功刪除組織用戶'
    },
    setAsPrimaryOrgUserSuccessMessage: {
        en: 'Successfully set org user as primary org user',
        es: 'Establecer correctamente el usuario de la organización como usuario principal de la organización',
        fr: 'L\'utilisateur de l\'organization a bien été défini comme utilisateur principal de l\'organization',
        ja: '組織ユーザーをプライマリ組織ユーザーとして正常に設定しました',
        zh: '成功將組織用戶設置為主要組織用戶'
    },
    sendTemporaryPasswordSuccessMessage: {
        en: 'Successfully sent temporary password to org user',
        es: 'Se envió correctamente la contraseña temporal al usuario de la organización',
        fr: 'Mot de passe temporaire envoyé avec succès à l\'utilisateur de l\'organization',
        ja: '一時パスワードが組織ユーザーに正常に送信されました',
        zh: '成功向組織用戶發送了臨時密碼'
    },
}

export const availableLanguagesForCountries: any = {
    AR: ['es'],
    BW: ['en'],
    CA: ['fr', 'en'],
    CL: ['es'],
    CN: ['zh'],
    CR: ['es'],
    GB: ['en'],
    GH: ['en'],
    GT: ['es'],
    HN: ['es'],
    IN: ['en'],
    JP: ['ja'],
    KE: ['en'],
    LS: ['en'],
    MW: ['en'],
    MX: ['es'],
    MZ: ['en'],
    NA: ['en'],
    NG: ['en'],
    NI: ['es'],
    SV: ['es'],
    SZ: ['en'],
    TZ: ['en'],
    UG: ['en'],
    ZA: ['en'],
    ZM: ['en']
}

export const getLangaugeDropDownOptionsByCountryCode = (countryCode: string) => {
    let languageOptions: any = [];
    switch (countryCode) {
        case 'AR':
        case 'CL':
        case 'CR':
        case 'GT':
        case 'HN':
        case 'NI':
        case 'SV':
        case 'MX':
            languageOptions = [
                {
                    label: 'English',
                    value: 'en'
                },
                {
                    label: 'Español',
                    value: 'es'
                }
            ]
            break
        case 'BW':
        case 'GB':
        case 'HN':
        case 'GH':
        case 'IN':
        case 'KE':
        case 'LS':
        case 'MW':
        case 'MZ':
        case 'NA':
        case 'NG':
        case 'SZ':
        case 'TZ':
        case 'UG':
        case 'ZA':
        case 'ZM':
            languageOptions = [
                {
                    label: 'English',
                    value: 'en'
                }
            ]
            break;
        case 'CA':
            languageOptions = [
                {
                    label: 'English',
                    value: 'en'
                },
                {
                    label: 'French',
                    value: 'fr'
                }
            ]
            break;
        case 'JA':
            languageOptions = [
                {
                    value: 'en',
                    label: 'English'
                },
                {
                    value: 'ja',
                    label: '日本人'
                },
            ]
            break
        case 'ZH':
            languageOptions = [
                {
                    value: 'en',
                    label: 'English'
                },
                {
                    value: 'zh',
                    label: '中文'
                },
            ]
            break
        default:
            languageOptions = [
                {
                    value: 'en',
                    label: 'English'
                },
                {
                    label: 'French',
                    value: 'fr'
                },
                {
                    value: 'ja',
                    label: '日本人'
                },
                {
                    label: 'Español',
                    value: 'es'
                },
                {
                    value: 'zh',
                    label: '中文'
                }
            ]
            break;
    }
    return languageOptions;
}
export const mexicoTaxIdLabel = 'RFC';
export const reactQueryConfigOptions: any = {
    refetchOnWindowFocus: false
}
export const appendixTypeList: any = {
    org_type_ngo: [
        {
            language: {
                en: 'Appendix A',
                es: 'Apéndice A',
                fr: 'Annexe A',
                ja: '付録A',
                zh: '附錄 A'
            },
            value: 'appendixa'
        },
        {
            language: {
                en: 'Appendix B',
                es: 'Apéndice B',
                fr: 'Appendice B',
                ja: '付録B',
                zh: '附錄 B'
            },
            value: 'appendixb'
        },
    ],
    org_type_governmental: [
        {
            language: {
                en: 'Appendix A',
                es: 'Apéndice A',
                fr: 'Annexe A',
                ja: '付録A',
                zh: '附錄 A'
            },
            value: 'appendixa'
        }
    ]
}
export const countryCodeToCurrenccySymbol: { [key: string]: string } = {
    AR: '$',
    BR: 'R$',
    BW: 'P',
    CA: '$',
    CL: '$',
    CN: '¥',
    CR: '₡',
    GB: '£',
    GH: '₵',
    GT: 'Q',
    HN: 'HNL',
    IN: '₹',
    JP: '¥',
    KE: 'Sh',
    LS: 'L',
    MW: 'MWK',
    MX: '$',
    MZ: 'MTn',
    NA: '$',
    NG: '₦',
    NI: 'C$',
    SV: '$',
    SZ: 'L',
    TZ: 'Sh',
    UG: 'Sh',
    ZA: 'ZAR',
    ZM: 'ZK'
}

export const UPLOAD_FILE_SIZE = 10485760;
export const SUPPORTED_FORMATS = [
    'application/pdf',
    'application/doc',
    'application/docx',
    'application/txt',
    'image/jpg',
    'image/png',
    'image/jpeg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/plain'
]
export const reviewPageAllowedDonationStatus: string[] = [
    'REQUEST_APPENDIX_B',
    'WAITING_FOR_NGO_ACKNOWLEDGEMENT',
    'WAITING_FOR_APPENDIX_B',
    'WAITING_FOR_INFORMATION_FROM_ORGANIZATION',
    'COMPLETED',
    'WAITING_FOR_ORGANIZATION_ACKNOWLEDGEMENT'
]

export enum DonationStatus {
    RequestForMoreInformation = 'REQUEST_FOR_INFORMATION',
    Draft = 'DRAFT',
    AwaitingPaymentAcknowledgment = 'AWAITING_PAYMENT_ACKNOWLEDGMENT',
    UnderCAReview = 'UNDER_CA_REVIEW',
    WaitingforAppendixB = 'WAITING_FOR_APPENDIX_B',
    SavedAppendixC = 'SAVED_APPENDIX_C',
    WaitingforAppendixCReview = 'WAITING_FOR_APPENDIX_C_REVIEW',
}

export const DashBoardTypes = {
    SocialImpactMonetaryDashBoard: 'SOCIAL_IMPACT_MONETARY_DASHBOARD'
}

export const countryLocaleAndCurrency: any = {
    AR: {
        locale: 'es-AR',
        currency: 'ARS'
    },
    BR: {
        locale: 'en-BR',
        currency: 'BOB'
    },
    BW: {
        locale: 'en-BW',
        currency: 'BWP'
    },
    CA: {
        locale: 'en-CA',
        currency: 'CAD'
    },
    CL: {
        locale: 'es-CL',
        currency: 'CLP'
    },
    CN: {
        locale: 'zh-CN',
        currency: 'CNY'
    },
    CR: {
        locale: 'es-CR',
        currency: 'CRC'
    },
    GB: {
        locale: 'en-GB',
        currency: 'GBP'
    },
    GH: {
        locale: 'en-GH',
        currency: 'GHC'
    },
    GT: {
        locale: 'es-GT',
        currency: 'GTQ'
    },
    HN: {
        locale: 'es-HN',
        currency: 'HNL'
    },
    IN: {
        locale: 'en-IN',
        currency: 'INR'
    },
    JP: {
        locale: 'ja-JP',
        currency: 'JPY'
    },
    KE: {
        locale: 'es-KE',
        currency: 'KES'
    },
    LS: {
        locale: 'es-LS',
        currency: 'ZAR'
    },
    MW: {
        locale: 'en-MW',
        currency: 'MWK'
    },
    MX: {
        locale: 'es-MX',
        currency: 'MXN'
    },
    MZ: {
        locale: 'en-MZ',
        currency: 'MZM'
    },
    NA: {
        locale: 'en-NA',
        currency: 'NAD'
    },
    NG: {
        locale: 'es-NG',
        currency: 'NGN'
    },
    NI: {
        locale: 'es-NI',
        currency: 'NIO'
    },
    SV: {
        locale: 'es-SV',
        currency: 'USD'
    },
    SZ: {
        locale: 'en-SZ',
        currency: 'SZL'
    },
    TZ: {
        locale: 'en-TZ',
        currency: 'TZS'
    },
    UG: {
        locale: 'en-UG',
        currency: 'UGX'
    },
    ZA: {
        locale: 'en-ZA',
        currency: 'ZAR'
    },
    ZM: {
        locale: 'en-ZM',
        currency: 'ZMW'
    }
}

export const priceAdjustValueByCountry: any = {
    AR: 1,
    BR: 1,
    BW: 1,
    CA: 1,
    CL: 100,
    CN: 1,
    CR: 1,
    GB: 1,
    GH: 1,
    GT: 1,
    HN: 1,
    IN: 1,
    JP: 1,
    KE: 1,
    LS: 1,
    MW: 1,
    MX: 1,
    MZ: 1,
    NA: 1,
    NG: 1,
    NI: 1,
    SV: 1,
    SZ: 1,
    TZ: 1,
    UG: 1,
    ZA: 1,
    ZM: 1
}


export const editableDonationStatuses = ['DRAFT', 'REQUEST_FOR_INFORMATION']

export const searchField: any = {
    'id': '_id',
    'donationDetail.orgName': 'orgName',
    'donationDetail.facilityNo': 'store',
    'organization.orgTaxId': 'taxId',
    'organization.organizationType.keyText': 'orgType',
    'audit.createdDtm': 'year',
    'currentStatusCode': 'status'
}

export const GOOODS_ONE_TIME_ENABLED_COUNTRIES: string[] = [
    'CL',
    'CR',
    'SV',
    'HN',
    'GT',
    'NI',
    'CA'
]

// export const FOOD_ONE_TIME_ENABLED_COUNTRIES: string[] = ['CL', 'MX', 'CR', 'SV', 'HN', 'GT', 'NI', 'CA']
export const FOOD_ONE_TIME_ENABLED_COUNTRIES: string[] = [
    'CL',
    'CR',
    'SV',
    'HN',
    'GT',
    'NI',
    'CA'
]

export const activitiesOption: any = {
    'NOT_STARTED': {
        en: 'Not Started',
        es: 'No empezado',
        fr: 'Pas commencé',
        ja: '始まっていない',
        zh: '没有开始'
    },
    'IN_PROGRESS': {
        en: 'In Progress',
        es: 'En curso',
        fr: 'En cours',
        ja: '進行中',
        zh: '进行中'
    },
    'COMPLETED': {
        en: 'Completed',
        es: 'Terminado',
        fr: 'Complété',
        ja: '完了',
        zh: '完全的'
    }
}
export const commonErrosMsg: any = {
    filedownloadErr: {
        en: 'Failed to download the report, please try again after some time or please contact the GDMS support team',
        es: 'No se pudo descargar el informe. Vuelva a intentarlo después de un tiempo o comuníquese con el equipo de soporte de GDMS.',
        fr: 'Échec du téléchargement du rapport, veuillez réessayer après un certain temps ou veuillez contacter l\'équipe d\'assistance GDMS',
        ja: 'レポートのダウンロードに失敗しました。しばらくしてから再試行するか、GDMSサポートチームに連絡してください。',
        zh: '無法下載報告，請稍後重試或聯繫 GDMS 支持團隊'
    },
    appendixMsg: {
        en: 'No appendices are available for this donation',
        es: 'No hay apéndices disponibles para esta donación.',
        fr: 'Aucune annexe nest disponible pour ce don',
        ja: 'この寄付に利用できる付録はありません',
        zh: '本次捐贈沒有附錄'
    }

}

export const sourceTypeTranslations: any = {
    internal: {
        en: 'Internal',
        es: 'Interno',
        fr: 'Interne',
        ja: '内部',
        zh: '内部'
    },
    external: {
        en: 'External',
        es: 'Externo',
        fr: 'Externe',
        ja: '外部',
        zh: '外部'
    }
}

export const subDonationTypeTranslations: any = {
    money: {
        en: 'Money',
        es: 'Dinero',
        fr: 'Argent',
        ja: 'お金',
        zh: '錢'
    },
    sponsorship: {
        en: 'Sponsorship',
        es: 'Patrocinio',
        fr: 'Parrainage',
        ja: 'スポンサーシップ',
        zh: '贊助商'
    }
}

export const monetaryDonationStatuses: any = {
    en: [
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.AWAITING_PAYMENT_ACKNOWLEDGMENT,
            code: 'AWAITING_PAYMENT_ACKNOWLEDGMENT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.AWAITING_PAYMENT_CONFIRMATION,
            code: 'AWAITING_PAYMENT_CONFIRMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.COUNTRY_HEAD_REVIEW,
            code: 'COUNTRY_HEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.LEGAL_TEAM_REVIEW,
            code: 'LEGAL_TEAM_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.UNDER_INITIAL_REVIEW,
            code: 'UNDER_INITIAL_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.UNDER_CSR_LEAD_REVIEW,
            code: 'UNDER_CSR_LEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.UNDER_BU_REVIEW,
            code: 'UNDER_BU_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.en.UNDER_VP_REVIEW,
            code: 'UNDER_VP_REVIEW'
        }
    ],
    es: [
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.AWAITING_PAYMENT_ACKNOWLEDGMENT,
            code: 'AWAITING_PAYMENT_ACKNOWLEDGMENT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.AWAITING_PAYMENT_CONFIRMATION,
            code: 'AWAITING_PAYMENT_CONFIRMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.COUNTRY_HEAD_REVIEW,
            code: 'COUNTRY_HEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.LEGAL_TEAM_REVIEW,
            code: 'LEGAL_TEAM_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.UNDER_INITIAL_REVIEW,
            code: 'UNDER_INITIAL_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.UNDER_CSR_LEAD_REVIEW,
            code: 'UNDER_CSR_LEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.UNDER_BU_REVIEW,
            code: 'UNDER_BU_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.es.UNDER_VP_REVIEW,
            code: 'UNDER_VP_REVIEW'
        }
    ],
    fr: [
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.AWAITING_PAYMENT_ACKNOWLEDGMENT,
            code: 'AWAITING_PAYMENT_ACKNOWLEDGMENT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.AWAITING_PAYMENT_CONFIRMATION,
            code: 'AWAITING_PAYMENT_CONFIRMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.COUNTRY_HEAD_REVIEW,
            code: 'COUNTRY_HEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.LEGAL_TEAM_REVIEW,
            code: 'LEGAL_TEAM_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.UNDER_INITIAL_REVIEW,
            code: 'UNDER_INITIAL_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.UNDER_CSR_LEAD_REVIEW,
            code: 'UNDER_CSR_LEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.UNDER_BU_REVIEW,
            code: 'UNDER_BU_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.fr.UNDER_VP_REVIEW,
            code: 'UNDER_VP_REVIEW'
        }
    ],
    ja: [
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.AWAITING_PAYMENT_ACKNOWLEDGMENT,
            code: 'AWAITING_PAYMENT_ACKNOWLEDGMENT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.AWAITING_PAYMENT_CONFIRMATION,
            code: 'AWAITING_PAYMENT_CONFIRMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.COUNTRY_HEAD_REVIEW,
            code: 'COUNTRY_HEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.LEGAL_TEAM_REVIEW,
            code: 'LEGAL_TEAM_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.UNDER_INITIAL_REVIEW,
            code: 'UNDER_INITIAL_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.UNDER_CSR_LEAD_REVIEW,
            code: 'UNDER_CSR_LEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.UNDER_BU_REVIEW,
            code: 'UNDER_BU_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.ja.UNDER_VP_REVIEW,
            code: 'UNDER_VP_REVIEW'
        }
    ],
    zh: [
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.AWAITING_PAYMENT_ACKNOWLEDGMENT,
            code: 'AWAITING_PAYMENT_ACKNOWLEDGMENT'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.AWAITING_PAYMENT_CONFIRMATION,
            code: 'AWAITING_PAYMENT_CONFIRMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.COUNTRY_HEAD_REVIEW,
            code: 'COUNTRY_HEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.LEGAL_TEAM_REVIEW,
            code: 'LEGAL_TEAM_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.UNDER_INITIAL_REVIEW,
            code: 'UNDER_INITIAL_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.UNDER_CSR_LEAD_REVIEW,
            code: 'UNDER_CSR_LEAD_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.UNDER_BU_REVIEW,
            code: 'UNDER_BU_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_MONEY.zh.UNDER_VP_REVIEW,
            code: 'UNDER_VP_REVIEW'
        }
    ],
}
export const recurringGoodsDonationStatuses: any = {
    en: [
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.AUTHORIZED,
            code: 'AUTHORIZED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.en.WAITING_FOR_REVIEW_FROM_CA,
            code: 'WAITING_FOR_REVIEW_FROM_CA'
        }

    ],
    es: [
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.AUTHORIZED,
            code: 'AUTHORIZED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.es.WAITING_FOR_REVIEW_FROM_CA,
            code: 'WAITING_FOR_REVIEW_FROM_CA'
        }
    ],
    fr: [
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.AUTHORIZED,
            code: 'AUTHORIZED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.fr.WAITING_FOR_REVIEW_FROM_CA,
            code: 'WAITING_FOR_REVIEW_FROM_CA'
        }
    ],
    ja: [
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.AUTHORIZED,
            code: 'AUTHORIZED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.REJECTED,
            code: 'REJECTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.ja.WAITING_FOR_REVIEW_FROM_CA,
            code: 'WAITING_FOR_REVIEW_FROM_CA'
        }
    ],
    zh: [
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.AUTHORIZED,
            code: 'AUTHORIZED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.SUBMITTED,
            code: 'SUBMITTED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.UNDER_CA_REVIEW,
            code: 'UNDER_CA_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.UNDER_AC_REVIEW,
            code: 'UNDER_AC_REVIEW'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.DRAFT,
            code: 'DRAFT'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.REQUEST_FOR_INFORMATION,
            code: 'REQUEST_FOR_INFORMATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.PENDING_CONTRACT_CREATION,
            code: 'PENDING_CONTRACT_CREATION'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.CONTRACT_CREATED_OUTSIDE_SYSTEM,
            code: 'CONTRACT_CREATED_OUTSIDE_SYSTEM'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.CONTRACT_CREATED,
            code: 'CONTRACT_CREATED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.COMPLETED,
            code: 'COMPLETED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.REJECTED,
            code: 'REJECTED'
        },

        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.CANCELLED,
            code: 'CANCELLED'
        },
        {
            name: donationsStatusText.DONATION_OF_GOODS_RECURRING.zh.WAITING_FOR_REVIEW_FROM_CA,
            code: 'WAITING_FOR_REVIEW_FROM_CA'
        }
    ],
}

export enum PAGETYPES  {
    VIEW = "VIEW",
    CREATE = "CREATE",
    CUSTOM = "CUSTOM"
}
